import request from '@/utils/request'

export function pageInit(id) {
    return request({
        url:'/api/AmzAdCampaigns/pageInit',
        method:'get',
        headers:{
            'profileId':id
        }
    });
}

export function listPage(params) {
    return request({
        url:'/api/AmzAdCampaigns/listPage',
        method:'get',
        params
    });
}

// 新增广告活动初始化
export function addInit(id) {
    return request({
        url:'/api/AmzAdCampaigns/addInit',
        method:'get',
        headers:{
            'profileId':id
        }
    });
}

export function addActive(data,id) {
    return request({
        url:'/api/AmzAdCampaigns',
        method:'post',
        data,
        headers:{
           'profileId':id
        }
    });
}

// 批量操作初始化
export function batchInit() {
    return request({
        url:'/api/AmzAdCampaigns/batchUpdateInit',
        method:'get',
    });
}

// 批量操作
export function batchUpdate(data){
    return request({
        url:'/api/AmzAdCampaigns/batchUpdate',
        method:'put',
        data
    });
}

// 新增组合初始化
export function addGroupInit() {
    return request({
        method:'get',
        url:'/api/amzAdPortfolios/addInit'
    })
}

// 新增广告组合
export function addgroup(data,id) {
    return request({
        url:"/api/amzAdPortfolios",
        method:'post',
        data,
        headers:{
            'profileId':id
        }
    });
}

// =========================================================同时新增广告组
export function getGoodsList(params) {
    return request({
        method:'get',
        url:'/api/amazon/asin/stockListPage',
        params
    })
}


// 关注与取消关注
export function focusOn(id) {
    return request({
        url:'/api/cpc/focus/add',
        method:'get',
        params:{
            campaignId:id
        }
    });
}
export function focusOff(id) {
    return request({
        url:'/api/cpc/focus/cancel',
        method:'get',
        params:{
            campaignId:id
        }
    });
}


// 编辑初始化
export function updateInit(id) {
    return request({
        url:`/api/AmzAdCampaigns/updateInit/${id}`,
        method:'get'
    });
}

// 修改
export function AmzAdCampaigns(data,id) {
    return request({
        url:'/api/AmzAdCampaigns',
        method:'put',
        data,
        headers:{
            'profileId':id
        }
    });
}


// 添加否定关键字
export function addNegativeKeyword(data,profileId) {
    return request({
        url:'/api/ad/keyword/addNegativeKeyword',
        method:'post',
        data,
        headers:{
            "profileId":profileId
        }
    });
}
// 添加否定商品
export function addNeTargets(data,profileId) {
    return request({
        url:'/api/ad/target/addNeTargets',
        method:'post',
        data,
        headers:{
            "profileId":profileId
        }
    });
}

// 查询否定投放===右侧回显
export function listNegativeTarget(params) {
    return request({
        url:'/api/ad/target/listNegativeTarget',
        method:'get',
        params
    });
}
export function listCampaignNegativeKeywords(params) {
    return request({
        url:'/api/ad/keyword/listCampaignNegativeKeywords',
        method:'get',
        params
    });
}


// 修改否定投放状态

export function archivedNeTarget(data,profileId) {
    return request({
        url:'/api/ad/target/archivedNeTarget',
        method:'put',
        data,
        headers:{
            "profileId":profileId
        }
    });
}
// 修改否定关键词状态
export function updateNeKeyword(data,profileId) {
    return request({
        url:'/api/ad/keyword/updateNeKeyword',
        method:'put',
        data,
        headers:{
            "profileId":profileId
        }
    });
}


export function updateBid(data,profileId) {
    return request({
        url:'/api/AmzAdCampaigns/updateBid',
        data,
        method:'put',
        headers:{
            "profileId":profileId
        }
    });
}

export function amzAdPortfolios(data,profileId) {
    return request({
        url:'/api/amzAdPortfolios',
        method:'put',
        data,
        headers:{
            "profileId":profileId
        }
    });
}

export function groupUpdateInit(id) {
    return request({
        url:`/api/amzAdPortfolios/updateInit/${id}`,
        method:'get',
    });
}

// 表现明细查询
export function listByCampaign(params) {
   return request({
     url: "/api/AmzAdCampaigns/listByCampaign",
     params,
     method: "get"
   })
}
